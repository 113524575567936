import React from 'react'
import { useStateValue } from '../context/StateProvider'
import CartContainer from './CartContainer'

function AboutUsPage() {
  const [{uploadedProducts,cartShow},dispatch] = useStateValue()
  return (
    <div className='min-w-full min-h-screen'>AboutUsPage
    <main className='w-full h-auto'>

    </main>
    {cartShow&&(
          <CartContainer />
        )}
    </div>
  )
}

export default AboutUsPage
