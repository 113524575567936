
import React, { useState } from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { motion } from 'framer-motion'

function CartItemsContainer({product}) {
    const [quantity,setQuantity] = useState(1)
    console.log(product.quantity)
  return (
    <div className='w-full p-1 px-2 rounded-lg bg-cartItem flex items-center  gap-2'>
                        <img 
                         src={product?.imageUrl}
                         alt=''
                         className='w-20 h-20 max-w-[60px] rounded-full object-contain' 
                        />
                        <div className='flex flex-col gap-2'>
                            <p className='text-base text-gray-50 '>{product?.title}</p>
                            <p className='text-md block  text-gray-300 font-semibold'> ${product?.price * quantity}</p>
                        </div>

                        <div className='group flex items-center gap-2 ml-auto cursor-pointer'>
                            <motion.div whileTap={{scale:0.8}}>
                                <BiMinus className='text-gray-50'/>
                            </motion.div>
                            <p className='w-5 h-5 rounded-sm bg-cartBg text-gray-50 flex items-center justify-center'>{product?.quantity}</p>
                            <motion.div whileTap={{scale:0.8}}>
                                <BiPlus className='text-gray-50'
                                />
                            </motion.div>
                        </div>
                        
                    </div>
  )
}

export default CartItemsContainer