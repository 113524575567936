import React, { useEffect, useState } from 'react'
import HomeContainer from './HomeContainer'
import {motion} from 'framer-motion'
import {MdChevronLeft,MdChevronRight} from 'react-icons/md'
import RowContainer from './RowContainer'
import { useStateValue } from '../context/StateProvider'

import CartContainer from './CartContainer'
function MainContainer() {

  const [{uploadedProducts,cartShow},dispatch] = useStateValue() 

  return (   

    <div className='w-full h-screen flex flex-col items-center justify-center '>        
        
        
    </div>
  )
}

export default MainContainer