import React, { useEffect } from 'react'
import Header from './components/Header'
import {Route,Routes} from 'react-router-dom'
import MainContainer from './components/MainContainer'
import CreateContainer from './components/CreateContainer'
import { AnimatePresence } from 'framer-motion'
import { getAllProducts } from './utils/firebaseFn'
import { useStateValue } from './context/StateProvider'
import { actionType } from './context/reducer'
import ProductPage from './components/ProductPage'
import AboutUsPage from './components/AboutUsPage'
import Footer from './components/Footer'
import './App.css'
import LoginPage from './Pages/LoginPage/LoginPage'
import RegisterPage from './Pages/Register/RegisterPage'
import { fetchUser } from './utils/fetchingLocalStorageData'
import ItemPage from './components/ItemPage'


function App() {
  
  const [{user,uploadedProducts},dispatch] = useStateValue();
  
  const fetchItems = async () => {
    await getAllProducts().then(item=>{
      dispatch({
        type: actionType.SET_Uploded_Products,
        uploadedProducts: item})
      console.log(item)
    })
  }
  useEffect(()=> {
    fetchItems()
  },[])
  return (
    <AnimatePresence >
        <div className='sexygradient'> 
        
        
      <Header />

      <main className='mt-14 md:mt-20 px-4 md:p-16 py-4 w-full h-full '>
        <Routes>
          <Route path='/*' element={<MainContainer />}/>          
          <Route path='/login' element={<LoginPage />} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path='/products' element={<ProductPage />}/>
          <Route path='/aboutus' element={<AboutUsPage />}/>
          <Route path='/createitem' element={<CreateContainer />}/>
          <Route path='/Products/*' element={<ItemPage product={uploadedProducts?.filter(product => product.id)} />}/>
        </Routes>
      </main>
      
      </div>
      
    </AnimatePresence>
  
  )
}

export default App