import Icecream from '../components/images/i1.png'
import F1 from '../components/images/f1.png'
import C3 from '../components/images/c3.png'
import Fi1 from '../components/images/fi1.png'

export const heroData =[
    {id:1,name:'Icecream',description:'Chocolate and Vanillia',image:Icecream,price:"$5.00"},
    {id:2,name:'Strawberry',description:'StrawberrySSS',image:F1,price:"$6.00"},
    {id:3,name:'Not IceCream',description:'not Vanilla not Chocolate',image:C3,price:"$7.00"},
    {id:4,name:'Fish',description:'Fishy Fish',image:Fi1,price:"$8.00"},
]
export const categories = [
    {
        id:101,
        name:"Coffee",
        urlParamName:"coffee",
        img:'coffe-cup.png'
    },
    {
        id:102,
        name:'CheeseCAKE',
        urlParamName:"cheesecake",
        img:'/cake.png'
    },
    {
        id:103,
        name:'Donuts',
        urlParamName:"donuts",
        img:'donut.png'
    },
    {
        id:104,
        name:'Cake',
        urlParamName:"Cake",
        img:'/cake.png'
    },
    {
        id:105,
        name:'all',
        urlParamName:"all",
        img:'/logo.png'
    },
    
]

export const discountCodes =[
    {
    code:'Vojd',
    discountPercent:100,
    discountProduct:'CheeseCake with Eggs'
    },
    {
        code:'Todor100',
    discountPercent:100,
    discountProduct:'CheeseCake with Eggs'
    },
    {
        code:'Coffee10',
    discountPercent:10,
    discountProduct:'Depresso',
    discountCategory:'Coffee',
    },
    {
        code:'Donut69',
        discountPercent:17,
        discountProduct:'Bubble Donut',
        discountCategory:'Donuts',
    },

    ]

    // {
    //     id:4,
    //     name:'Soft Drinks',
    //     urlParamName:"softdrinks",
        
    // },
    // {
    //     id:5,
    //     name:'Promotions',
    //     urlParamName:"promotions",
    // },
    // {
    //     id:6,
    //     name:'Cake',
    //     urlParamName:"cake",
    // },